<template>
  <!-- phone START -->
  <div class="account-phone">

    <!-- form:phone START -->
    <el-form
      status-icon
      :rules="rules"
      ref="registerForm"
      :model="registerForm"
      class="login-form"
      :statusIcon="false"
      @submit.prevent.native="submitForm('registerForm')"
    >
      <!-- form:phone:phone START -->
      <el-form-item prop="phone">
        <el-input
          inputmode="tel"
          :placeholder="`${$t('login.phone')} *`"
          v-model="registerForm.phone"
          @keyup.enter.native="submitForm('registerForm')"
        ></el-input>
      </el-form-item>
      <!-- form:phone:phone END -->

      <!-- form:phone:phone:confirm START -->
      <el-form-item prop="confirmPhone">
        <el-input
          inputmode="tel"
          :placeholder="`${$t('login.confirmphone')} *`"
          v-model="registerForm.confirmPhone"
          @keyup.enter.native="submitForm('registerForm')"
        ></el-input>
      </el-form-item>
      <!-- form:phone:phone:confirm END -->

      <!-- form:phone:password START -->
      <el-form-item prop="password">
        <el-input
          :type="type"
          :placeholder="`${$t('login.password')} *`"
          v-model="registerForm.password"
          @keyup.enter.native="submitForm('registerForm')"
          autocomplete="off"
        >
          <i
            slot="suffix"
            class="el-icon-view"
            v-on:click="showHidePassword()"
          ></i>
        </el-input>
      </el-form-item>
      <!-- form:phone:password END -->

      <!-- form:phone:submit START -->
      <el-form-item>

        <el-button
          class="btn-default"
          type="primary"
          v-on:click="submitForm('registerForm')"
        >{{ $t('login.save') }}</el-button>
      </el-form-item>
      <!-- form:phone:submit END -->
    </el-form>
    <!-- form:phone END -->

    <!-- form:phone:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- form:phone:loading END -->
  </div>
  <!-- phone END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';
import validateMixin from '@/mixins/validateMixin';

export default {
  name: 'phone',
  mixins: [titleMixin, validateMixin],
  components: {
    LoadingDefault: () => import('../../components/shared/loading/Default.vue'),
  },
  data() {
    const checkPhone = (rule, value, callback) => {
      const phoneReg = /^(?:(?:00|\+)|0)[1-9]\d{1,2}?(?:[\d.\s\- /()]*)$/mg; // /^(?:(?:00|\+)|0)[1-9]\d{1,2}?(?:[\d.\s]*)$/mg; // eslint-disable-line
      if (!value) {
        return callback(new Error('Phone number cannot be empty'));
      }
      if (phoneReg.test(value)) {
        callback();
      } else {
        callback(new Error('The phone number format is incorrect'));
      }
      return value;
    };
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('account.phone.headline'),

      user: null,
      userID: JSON.parse(localStorage.getItem('user')).id,

      /**
       * Loading
       */
      loadingVisible: false,
      loadingHeadline: this.$t('loading.update.headline'),
      loadingExcerpt: this.$t('loading.update.excerpt'),

      /**
       * Form (Variables)
       */
      serverErrorMessages: [],
      globalErrorMessage: false,
      type: 'password',

      /**
       * Form (Models) + Pass data to mixin
       */
      confirmationPhone: this.$t('login.confirmationphone'),
      registerForm: {
        phone: '',
        confirmPhone: '',
        password: '',
      },
      /**
       * Form (Rules - Validation)
       */
      rules: {
        phone: [
          {
            required: true,
            validator: checkPhone,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
          {
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        confirmPhone: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
          {
            validator: this.validateConfirmPhone,
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
        password: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
          {
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
      },
    };
  },
  async created() {
    this.user = await this.FETCH_USER();
  },
  methods: {
    async FETCH_USER() {
      await this.$store.dispatch('setUser');
      return this.$store.getters.user;
    },

    submitForm(formName) {
      const formData = new FormData();
      if (formName === 'registerForm') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loadingVisible = true;
            /**
             * Locale
             */
            formData.append('locale', localStorage.getItem('lang'));
            /**
             * Data
             */
            formData.append('phoneNumber', this.registerForm.phone);
            formData.append('password', this.registerForm.password);

            // Display the key/value pairs
            /* [...formData.entries()].forEach((row) => {
              console.log(`${row}`);
            }); */

            // Update user data
            this.$http.post(`${process.env.VUE_APP_BASE_URL}user/phoneNumber`, formData, {
              headers: {
                'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
                Authorization: `Bearer ${localStorage.getItem('auth')}`,
              },
            })
              .then((response) => {
                if (response.data) {
                  // Close loading
                  this.loadingVisible = false;

                  const user = response.data;

                  // mutating to store for client rendering &
                  // saving user data in cookie for server rendering
                  this.updateItem('user', JSON.stringify(user));

                  // notification - error
                  this.success();
                } else {
                  this.globalErrorMessage = true;
                  this.resetForm.agbs = false;

                  console.log('error register submit!!');
                }
              })
              .catch((error) => {
                if (!error.response) {
                  // network error
                  this.errorStatus = 'Error: Network Error';
                } else {
                  this.errorStatus = error.response.data;
                  this.loadingVisible = false;
                  this.serverErrorMessages = this.errorStatus.errors;

                  this.serverErrorMessages.forEach((message) => {
                    this.error(`${this.$t(`error.${message.code}`)}`);
                  });
                }
              });
          }
        });
      }
    },

    resetForm(formName) {
      if (this.$refs[formName]) {
        this.globalErrorMessage = false;
        this.$refs[formName].resetFields();
        this.type = 'password';
      }
    },

    showHidePassword() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },

    updateItem(item, value) {
      const prevData = JSON.parse(localStorage.getItem(item));
      Object.keys(value).forEach((val) => {
        prevData[val] = value[val];
      });
      localStorage.setItem(item, JSON.stringify(prevData));
    },

    success() {
      this.$notify({
        message: this.$t('notification.success'),
        type: 'success',
        position: 'bottom-right',
      });

      // clean up input fields
      setTimeout(() => {
        this.resetForm('registerForm');
      }, 750);
    },

    error(message) {
      this.$notify({
        message: this.$t(`${message}`),
        type: 'error',
        position: 'bottom-right',
      });
    },
  },
};
</script>
